import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>About Us | Tennis Club Coslada</title>
        <meta name={"description"} content={"Quality Service, Exceptional Care"} />
        <meta
          property={"og:title"}
          content={"About Us | Tennis Club Coslada"}
        />
        <meta
          property={"og:description"}
          content={"Quality Service, Exceptional Care"}
        />
        <meta
          property={"og:image"}
          content={"https://turinamander.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://turinamander.com/img/34538768.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://turinamander.com/img/34538768.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://turinamander.com/img/34538768.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://turinamander.com/img/34538768.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://turinamander.com/img/34538768.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://turinamander.com/img/34538768.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="--color-primary"
        padding="60px 0"
        sm-padding="40px 0"
      >
        <Box
          margin="-16px -16px -16px -16px"
          padding="0px 0px 0px 0px"
          display="flex"
          width=" "
          flex-wrap="wrap"
        >
          <Box
            padding="16px 16px 16px 16px"
            display="flex"
            width="50%"
            lg-width="100%"
          >
            <Box
              padding="98px 64px 98px 64px"
              display="flex"
              flex-direction="column"
              background="--color-light"
              color="--dark"
            >
              <Text
                as="h4"
                margin="12px 0"
                font="--base"
                color="--grey"
                letter-spacing="1px"
                text-transform="uppercase"
              >
               About Us
              </Text>
              <Text
                as="h2"
                margin="12px 0"
                font="--headline2"
                md-font="--headline3"
              >
              Our Expert Team
              </Text>
          <Text
            margin="10px 0px 0px 0px"
            color="--darkL2"
            font="--lead"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
           Our mechanics aren’t just workers, they are car enthusiasts and experts in their field. With a thorough selection process and continuous training, we guarantee that your vehicle is in capable hands. Each member is dedicated to upholding the highest standards of service quality and automotive care.
          </Text>
            </Box>
          </Box>
          <Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
            <Box
              padding="16px 16px 16px 16px"
              display="flex"
              width="100%"
              lg-width="33.3333%"
              md-width="100%"
              sm-width="100%"
            >
              <Box
                padding="0px 0px 624px 0px"
                display="flex"
                flex-direction="column"
                background="url(https://turinamander.com/img/3.jpg) center/cover"
                width="100%"
                lg-padding="0px 0px 248px 0px"
                sm-padding="0px 0px 380px 0px"
              />
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://turinamander.com/img/4.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Comprehensive Automotive Services
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
           At Midas AutoWorks, we understand the importance of a reliable and efficient vehicle. Our comprehensive range of services is designed to meet all your automotive needs, ensuring your vehicle performs at its best. From routine maintenance to complex repairs, our experienced technicians are equipped to handle it all.
            <br />
            <br />
            At Midas AutoWorks, we offer a detailed suite of services to keep your car running smoothly:
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Routine Maintenance & Advanced&nbsp;Diagnostics
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
           <b>Oil Changes:</b> Ensure your engine runs smoothly with high-grade oil and filter replacements.
            <br />
            <b>Tire Services:</b> From rotations to balancing, our tire services help extend the life of your tires and improve safety.
            <br />
            <b>Brake Checks:</b> Regular inspections and maintenance to keep your braking system responsive and reliable.
            <br />
            <b>Engine Diagnostics:</b> Utilize the latest technology to quickly identify engine problems and prevent future issues.
            <br />
            <b>Electrical Systems Testing:</b> Comprehensive checks to ensure all electrical components are operating correctly.

          </Text>
        </Box>
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://turinamander.com/img/5.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
      </Section>

      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://turinamander.com/img/6.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Repair Services & Custom Solutions
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            <b>Transmission Repairs:</b> Expert repair and maintenance for automatic and manual transmissions.
            <br />
            <b>Suspension Work:</b> Enhance ride comfort and handling with our suspension repair services.
            <br />
            <b>AC and Heating:</b> Keep your vehicle’s climate control functioning year-round with our HVAC services.
            <br />
            <b>Performance Upgrades:</b> Boost your vehicle’s performance with upgraded parts and tuning.
            <br />
            <b>Body Work:</b> From minor dents to major repairs, our body shop will have your car looking its best.
          </Text>
        </Box>
      </Section>
      <Section
        padding="100px 0 120px 0"
        sm-padding="80px 0 90px 0"
        quarkly-title="Statistics-10"
      >
        <Text
          margin="0px 0px 20px 0px"
          letter-spacing="2px"
          color="#cd9f55"
          font="normal 500 20px/1.5 --fontFamily-sansVerdana"
          border-color="#dca654"
        >
          Let's Get Your Car in Top Shape
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          lg-flex-direction="column"
        >
          <Box min-width="100px" min-height="100px" lg-width="100%">
            <Text
              margin="0 0px 0px 0px"
              font="normal 600 26px/1.2 --fontFamily-serifGeorgia"
              color="#050c32"
              sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia"
            >
              Visit Midas AutoWorks and experience first-hand how we can enhance the performance and longevity of your vehicle. Your car deserves professional care, and we’re here to deliver it.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
